import React from 'react';
import PropTypes from 'prop-types';

import Actions from './TeacherCell/Actions';
import Contacts from './TeacherCell/Contacts';
import Name from './TeacherCell/Name';

export const dataLabelVariants = Object.freeze({
  name: 'NAME',
  contacts: 'CONTACTS',
  actions: 'ACTIONS',
});

const TeacherCell = ({ dataLabel, dataItem }) => {
  const {
    first_name: firstName,
    last_name: lastName,
    middle_name: middleName,
    phone,
    email,
    id,
  } = dataItem;

  switch (dataLabel) {
    case dataLabelVariants.name: {
      return (
        <Name firstName={firstName} lastName={lastName} middleName={middleName} />
      );
    }
    case dataLabelVariants.contacts: {
      return (
        <Contacts phone={phone} email={email} />
      );
    }
    case dataLabelVariants.actions: {
      return (
        <Actions id={id} />
      );
    }
    default: {
      return null;
    }
  }
};

TeacherCell.propTypes = {
  dataLabel: PropTypes.string.isRequired,
  dataItem: PropTypes.shape().isRequired,
};

export default TeacherCell;
