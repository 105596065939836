export default Object.freeze({
  LOGIN: () => '/users/auth/',
  PROFILE: () => '/ao2/user/profile/',
  TEACHER_SELECTION: (id = '') =>
    `/manager/claims/teacher_selection/${id.toString().length > 0 ? `${id}/` : ''}`,
  ARBITRATION: (id = '') =>
    `/manager/claims/arbitration/${id.toString().length > 0 ? `${id}/` : ''}`,
  INTERVALS: () => '/manager/intervals/',
  LECTURE_PLAN: () => '/manager/lecture/plan/',
  LECTURE_TRANSFER: () => '/manager/lecture/transfer/',
  SET_TEACHER: () => '/manager/set_teacher/',
  GET_TEACHERS: () => '/manager/teachers/',
  GET_MANAGERS: () => '/manager/',
});
