import React from 'react';
import { connect } from 'react-redux';
import {
  Box, Container, CircularProgress, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Header from './components/Layout/Header';
import Snacks from './components/Layout/Snacks';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },
  mainCont: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  loaderCont: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.background.default,
    top: 0,
    left: 0,
    zIndex: 999999,
  },
}));

const Layout = ({ children, fetching }) => {
  const css = useStyles();

  return (
    <Box className={css.root}>
      <Header />
      <Snacks />
      {fetching.length > 0 && (
        <Grid container justify="center" alignItems="center" className={css.loaderCont}>
          <Grid item>
            <CircularProgress size={64} color="secondary" />
          </Grid>
        </Grid>
      )}
      <Container maxWidth="xl" className={css.mainCont}>
        {children}
      </Container>
    </Box>
  );
};

Layout.propTypes = {
  fetching: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ common: { fetching } }) => ({ fetching });

export default connect(mapStateToProps)(Layout);
