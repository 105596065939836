import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Image = ({
  src, fallback, className, style,
}) => {
  const [stateSrc, setSrc] = useState(src);
  useEffect(() => {
    const { REACT_APP_MEDIA_PATH: baseUrl } = process.env;
    if (src) {
      setSrc(baseUrl + src);
    } else if (fallback && typeof fallback === 'string') {
      setSrc(fallback);
    }
  }, [src, fallback, setSrc]);

  return (
    <img
      src={stateSrc}
      alt=""
      onError={() => fallback && setSrc(fallback)}
      className={className}
      style={style}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
  fallback: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

Image.defaultProps = {
  src: false,
  fallback: false,
  className: '',
  style: {},
};

export default Image;
