import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  Toolbar,
  Appointments,
  DateNavigator,
} from '@devexpress/dx-react-scheduler-material-ui';

import { educationTypes } from '../../../utils/models';

import { Appointment, AppointmentContent } from './Appointment';
import {
  DayScaleCell, TimeScaleLabel, TimeTableCell, TimeScaleLayout,
} from './WeekView';

const schedulerProps = Object.freeze({
  locale: 'ru-RU',
  firstDayOfWeek: 1,
});

const weekViewProps = Object.freeze({
  startDayHour: 0,
  endDayHour: 24,
});

const parseEducationType = (typeName) => {
  const type = Object.entries(educationTypes).find(([, { apiName }]) => apiName === typeName)
    || educationTypes.both;
  const [foundTypeName] = type;
  return foundTypeName;
};

const createData = (intervals = []) => {
  if (intervals.length === 0) return [];
  const data = intervals
    .map(
      ({
        id,
        start_datetime: startDate,
        end_datetime: endDate,
        is_visible: visible,
        education_type: educationType,
      }) =>
        visible && {
          startDate,
          endDate,
          id,
          type: parseEducationType(educationType),
        },
    )
    .filter((el) => el);
  return data;
};

const View = memo(({ intervals }) => {
  const [currentDate, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [data] = useState(createData(intervals));

  return (
    <Scheduler {...schedulerProps} data={data}>
      <ViewState currentDate={currentDate} onCurrentDateChange={setDate} />
      <WeekView
        {...weekViewProps}
        timeTableCellComponent={TimeTableCell}
        timeScaleLayoutComponent={TimeScaleLayout}
        timeScaleLabelComponent={TimeScaleLabel}
        dayScaleCellComponent={DayScaleCell}
      />
      <Appointments
        appointmentComponent={Appointment}
        appointmentContentComponent={AppointmentContent}
      />
      <Toolbar />
      <DateNavigator />
    </Scheduler>
  );
});

View.propTypes = {
  intervals: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default View;
