import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box, Typography, Grid, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { claimStatus } from '../../utils/models';
import { editTeacherSelectionClaim, getTeachersList } from '../../store/modules/selection';
import { parseProbableNull } from '../../utils/validators';

import TeacherParams from './Claim/TeacherParams';
import ManagersSelect from '../Common/ManagerSelect';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    '&> div': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const Claim = ({
  currentClaim, userId, editClaim, getTeachers, history,
}) => {
  const css = useStyles();

  const {
    status,
    with_native_teacher: native,
    // gender,
    // age_from: ageFrom,
    // age_to: ageTo,
  } = currentClaim;
  const goToTeacherSelection = async () => {
    const { id } = currentClaim;
    const teachersListQuery = {
      claim_id: id,
      with_native_teacher: native,
      // age_from: ageFrom,
      // age_to: ageTo,
      // gender,
    };
    await getTeachers(teachersListQuery);
    await editClaim(id, { status: claimStatus.PENDING, manager_user: userId });
    history.push('/teachers_list');
  };

  const { id: managerId } = parseProbableNull(currentClaim.manager_user);

  const onTakeAdvantageClick = async (selectedStatus, selectedManagerId = null) => {
    const { id } = currentClaim;
    await editClaim(id, {
      status: selectedStatus,
      manager_user: selectedManagerId === null ? userId : selectedManagerId,
    });
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Параметры подбора преподавателя
      </Typography>
      <TeacherParams />
      <Grid container justify="flex-end" alignItems="center" className={css.container}>
        {status !== claimStatus.CANCELLED && status !== claimStatus.ENDED && (
          <ManagersSelect
            onSelectManager={(selectedManagerId) =>
              onTakeAdvantageClick(claimStatus.PENDING, selectedManagerId)}
          />
        )}
        {(status === claimStatus.NEW
          || (status === claimStatus.PENDING && managerId === userId)) && (
          <Grid item>
            <Button variant="contained" color="secondary" onClick={() => goToTeacherSelection()}>
              Подобрать преподавателя
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Claim.propTypes = {
  currentClaim: PropTypes.shape().isRequired,
  userId: PropTypes.number.isRequired,
  editClaim: PropTypes.func.isRequired,
  getTeachers: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({
  selection: { currentClaim },
  auth: {
    user: { id },
  },
}) => ({
  currentClaim,
  userId: id,
});

const mapDispatchToProps = {
  editClaim: editTeacherSelectionClaim,
  getTeachers: getTeachersList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Claim));
