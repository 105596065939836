import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { claimStatus } from '../utils/models';

const useStyles = makeStyles((theme) => ({
  paperCont: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  actionsPanel: {
    padding: theme.spacing(3),
  },
}));

export default (WrappedComponent) => {
  const ClaimsListLayout = ({
    claimsList = [], fetchedClaims, getClaimsList, editClaim, user,
  }) => {
    useEffect(() => {
      async function fetchClaims() {
        const claims = await getClaimsList();
        return claims;
      }
      if (!fetchedClaims) {
        fetchClaims();
      }
    }, [getClaimsList, fetchedClaims]);
    const css = useStyles();
    const hasClaims = claimsList.length > 0;

    const editClaimHandler = async (id, body = { status: claimStatus.CANCELLED }) => {
      await editClaim(id, body);
    };

    return (
      <Container maxWidth="lg">
        <Paper elevation={1} className={css.paperCont}>
          <Typography variant="h2" gutterBottom>
            Список активных заявок
          </Typography>
          {hasClaims ? (
            <WrappedComponent
              claimsList={claimsList}
              css={css}
              user={user}
              editClaim={editClaimHandler}
            />
          ) : (
            <Typography variant="body1" gutterBottom>
              Заявки отсутствуют
            </Typography>
          )}
        </Paper>
      </Container>
    );
  };

  ClaimsListLayout.propTypes = {
    fetchedClaims: PropTypes.bool.isRequired,
    claimsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    getClaimsList: PropTypes.func.isRequired,
    editClaim: PropTypes.func.isRequired,
    user: PropTypes.shape({}).isRequired,
  };

  return ClaimsListLayout;
};
