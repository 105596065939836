import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';

import SnackContent from './Snacks/SnackContent';

class Snacks extends Component {
  state = {
    open: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.snack.id !== prevProps.snack.id) {
      if (!this.state.open) {
        this.updateOpened();
      }
    }
  }

  updateOpened() {
    this.setState({ open: true });
  }

  render() {
    const { snack } = this.props;
    const { open } = this.state;

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        open={open}
      >
        <SnackContent
          message={snack.text}
          variant={snack.type}
          onClose={() => this.setState({ open: false })}
        />
      </Snackbar>
    );
  }
}

Snacks.propTypes = {
  snack: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ common: { snacks, snack } }) => ({ snacks, snack });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Snacks);
