import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Button,
  Divider,
} from '@material-ui/core';
import { ExpandMore as ExpandIcon } from '@material-ui/icons';

import ExpansionSummary from '../ClaimList/ExpansionSummary';
import ExpansionDetails from '../ClaimList/ExpansionDetails';

const ClaimsList = memo(({ claimsArr = [], type }) => (
  <Box marginTop={3}>
    {claimsArr.length > 0 ? (
      claimsArr.map(
        ({
          id,
          user: { first_name: firstName, last_name: lastName },
          planned_start_date: plannedDate,
          comment,
          course: {
            name: courseName,
            subject: { name: subjectName, icon: courseIcon },
          },
          students_group: { name: groupName, education_type_name: groupEducationTypeName },
          status,
          type: claimType = false,
        }) => (
          <ExpansionPanel key={id}>
            <ExpansionPanelSummary expandIcon={<ExpandIcon />}>
              <ExpansionSummary
                id={id}
                user={{ firstName, lastName }}
                group={{
                  name: groupName,
                  educationTypeName: groupEducationTypeName,
                }}
                status={status}
                arbitrationType={claimType}
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ExpansionDetails
                comment={comment}
                courseName={courseName}
                subjectName={subjectName}
                courseIcon={courseIcon}
                plannedDate={plannedDate}
                id={id}
              />
            </ExpansionPanelDetails>
            <Divider />
            <Box p={3}>
              <ExpansionPanelActions>
                <Link to={{ pathname: `/${type}/${id}` }} style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="secondary">
                    Перейти к заявке
                  </Button>
                </Link>
              </ExpansionPanelActions>
            </Box>
          </ExpansionPanel>
        ),
      )
    ) : (
      <Typography variant="body1">Заявки отсутствуют</Typography>
    )}
  </Box>
));

ClaimsList.propTypes = {
  claimsArr: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string.isRequired,
};

export default ClaimsList;
