import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Button,
  Divider,
} from '@material-ui/core';
import { ExpandMore as ExpandIcon } from '@material-ui/icons';

import { getArbitrationClaims, editArbitrationClaim } from '../store/modules/arbitration';

import ExpansionSummary from '../components/ClaimList/ExpansionSummary';
import ExpansionDetails from '../components/ClaimList/ExpansionDetails';

import { claimStatus } from '../utils/models';

import withClaimsListLayout from '../hocs/withClaimsListLayout';

const createBody = (user = {}) => ({
  status: claimStatus.PENDING,
  manager_user: user.id,
});

const Arbitration = ({
  claimsList = [], css, editClaim, user,
}) =>
  claimsList.map(
    ({
      id,
      user: { first_name: firstName, last_name: lastName },
      planned_start_date: plannedDate,
      comment,
      course: {
        name: courseName,
        subject: { name: subjectName, icon: courseIcon },
      },
      students_group: { name: groupName, education_type_name: groupEducationTypeName },
      status,
      type: claimType = false,
    }) => (
      <ExpansionPanel key={id}>
        <ExpansionPanelSummary expandIcon={<ExpandIcon />}>
          <ExpansionSummary
            id={id}
            user={{ firstName, lastName }}
            group={{
              name: groupName,
              educationTypeName: groupEducationTypeName,
            }}
            status={status}
            arbitrationType={claimType}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ExpansionDetails
            comment={comment}
            courseName={courseName}
            subjectName={subjectName}
            courseIcon={courseIcon}
            plannedDate={plannedDate}
            id={id}
          />
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions className={css.actionsPanel}>
          {status === claimStatus.NEW && (
            <>
              <Button onClick={() => editClaim(id)}>Отменить заявку</Button>
              <Button color="secondary" onClick={() => editClaim(id, createBody(user))}>
                Взять в работу
              </Button>
            </>
          )}
          <Link to={{ pathname: `/arbitration/${id}` }} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="secondary">
              Перейти к заявке
            </Button>
          </Link>
        </ExpansionPanelActions>
      </ExpansionPanel>
    ),
  );

Arbitration.propTypes = {
  claimsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  css: PropTypes.objectOf(PropTypes.string),
  editClaim: PropTypes.func.isRequired,
  user: PropTypes.shape({ id: PropTypes.number }).isRequired,
};

Arbitration.defaultProps = {
  css: {},
};

const mapStateToProps = ({ arbitration: { claimsList, fetchedClaims }, auth: { user } }) => ({
  claimsList,
  fetchedClaims,
  user,
});

const mapDispatchToProps = {
  getClaimsList: getArbitrationClaims,
  editClaim: editArbitrationClaim,
};

export default connect(mapStateToProps, mapDispatchToProps)(withClaimsListLayout(Arbitration));
