import React from 'react';
import { connect } from 'react-redux';

import { getArbitrationClaims, setCurrentClaim } from '../store/modules/arbitration';

import withClaimItemLayout from '../hocs/withClaimItemLayout';
import Claim from '../components/ArbitrationItem/Claim';

const ArbitrationItem = () => (
  <Claim />
);

const mapStateToProps = ({ arbitration: { claimsList, currentClaim, fetchedClaims } }) => ({
  claimsList,
  currentClaim,
  fetchedClaims,
});

const mapDispatchToProps = {
  getClaimsList: getArbitrationClaims,
  setClaim: setCurrentClaim,
};

export default connect(mapStateToProps, mapDispatchToProps)(withClaimItemLayout(ArbitrationItem));
