import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box, Typography, Grid, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { claimStatus } from '../../utils/models';
import { parseProbableNull } from '../../utils/validators';
import { editArbitrationClaim } from '../../store/modules/arbitration';

import ContactsSection from './Claim/ContactsSection';
import Description from './Claim/Description';
import Logs from './Claim/Logs';
import ManagersSelect from '../Common/ManagerSelect';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    '&> div': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const Claim = ({ currentClaim, userId, editClaim }) => {
  const css = useStyles();

  const onTakeAdvantageClick = async (status, selectedManagerId = null) => {
    const { id } = currentClaim;
    await editClaim(id, {
      status,
      manager_user: selectedManagerId === null ? userId : selectedManagerId,
    });
  };

  const {
    user: claimUser,
    manager_user: managerUser,
    status,
    lecture,
    lecture: { bbb_logs: logs = [] },
  } = currentClaim;
  const { id: managerId } = parseProbableNull(managerUser);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Данные занятия
      </Typography>
      <Description data={lecture} />
      <Typography variant="h4" gutterBottom>
        Логи занятия от BBB
      </Typography>
      <Logs data={logs} />
      <Typography variant="h4" gutterBottom>
        Контактные данные участников заявки
      </Typography>
      <ContactsSection user={claimUser} manager={managerUser} />
      <Grid container justify="flex-end" alignItems="center" className={css.container}>
        {(status !== claimStatus.CANCELLED && status !== claimStatus.ENDED) && (
          <ManagersSelect
            onSelectManager={(selectedManagerId) =>
              onTakeAdvantageClick(claimStatus.PENDING, selectedManagerId)}
          />
        )}
        {status === claimStatus.PENDING && managerId === userId && (
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onTakeAdvantageClick(claimStatus.ENDED)}
            >
              Закрыть заявку
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Claim.propTypes = {
  currentClaim: PropTypes.shape().isRequired,
  userId: PropTypes.number.isRequired,
  editClaim: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ arbitration: { currentClaim }, auth: { user } }) => ({
  currentClaim,
  userId: user === null ? undefined : user.id,
});

const mapDispatchToProps = {
  editClaim: editArbitrationClaim,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Claim));
