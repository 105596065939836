import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  CheckCircle, Error as ErrorIcon, Warning, Close as CloseIcon,
} from '@material-ui/icons';
import { SnackbarContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { amber, green } from '@material-ui/core/colors';

import { snackTypes } from '../../../store/modules/common';

const useStyles = makeStyles((theme) => ({
  wrap: {
    margin: theme.spacing(1),
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const variantIcon = {
  [snackTypes.success]: CheckCircle,
  [snackTypes.error]: ErrorIcon,
  [snackTypes.warning]: Warning,
};

const SnackContent = (props) => {
  const classes = useStyles();
  const {
    message, onClose, variant, ...restProps
  } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={cx(classes[variant], classes.wrap)}
      aria-describedby="client-snackbar"
      message={(
        <span id="client-snackbar" className={classes.message}>
          <Icon className={cx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      )}
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...restProps}
    />
  );
};

SnackContent.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([snackTypes.error, snackTypes.success, snackTypes.warning]).isRequired,
};

export default SnackContent;
