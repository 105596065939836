export const claimStatus = Object.freeze({
  NEW: 'NEW',
  ENDED: 'ENDED',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
});

export const genders = Object.freeze({
  FEMALE: 'Женский',
  MALE: 'Мужской',
});

export const educationTypes = Object.freeze({
  online: {
    apiName: 'ONLINE',
    humanName: 'Онлайн',
  },
  offline: {
    apiName: 'OFFLINE',
    humanName: 'Офлайн',
  },
  both: {
    apiName: 'OFFLINE_AND_ONLINE',
    humanName: 'Онлайн/Офлайн',
  },
});
