import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';

const logOut = () => {
  window.localStorage.removeItem('ALIBRA_MANAGER_TOKEN');
  window.location.reload();
};

const User = ({ isAuthorized, user }) =>
  isAuthorized
  && user !== null && (
    <Grid container justify="flex-end" alignItems="center">
      <Grid item>
        <Typography variant="body1" align="right">
          <span>Добро пожаловать,&nbsp;</span>
          <b>
            <span>{user.firstName}</span>
            {user.lastName && (
              <span>
                &nbsp;
                {user.lastName}
              </span>
            )}
            !
          </b>
        </Typography>
      </Grid>
      <Grid
        item
        style={{ marginLeft: 12, cursor: 'pointer' }}
        onClick={logOut}
      >
        <ExitToApp />
      </Grid>
    </Grid>
  );

User.propTypes = {
  isAuthorized: PropTypes.bool,
  user: PropTypes.shape({}),
};

const mapStateToProps = ({ auth: { isAuthorized, user } }) => ({
  isAuthorized,
  user,
});

export default connect(mapStateToProps)(User);
