import { provider, apiEndpoints } from '../../../utils/api';
import { showSnack, snackTypes, setFetching } from '../common';

const initialState = {
  loginData: {
    email: '',
    password: '',
  },
  isAuthorized: false,
  user: null,
};

const actionTypes = {
  SET_LOGIN_DATA: 'SET_LOGIN_DATA',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  SET_AUTH: 'SET_AUTH',
};

export default (state = initialState, action = { type: null, payload: null }) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_LOGIN_DATA: {
      const { loginData } = state;
      const newLoginData = { ...loginData, ...payload };
      return {
        ...state,
        loginData: newLoginData,
      };
    }
    case actionTypes.LOGIN_SUCCESS: {
      const {
        email, token, isTeacher, firstName, lastName, id,
      } = payload;
      const user = {
        firstName,
        lastName,
        id,
        email,
      };
      return {
        ...state,
        isTeacher,
        token,
        user,
      };
    }
    case actionTypes.SET_AUTH: {
      return {
        ...state,
        isAuthorized: payload,
      };
    }
    default: {
      return state;
    }
  }
};

// Actions
export const setLoginData = (patch) => (dispatch) =>
  dispatch({ type: actionTypes.SET_LOGIN_DATA, payload: patch });

export const setAuth = (authorized = false) => (dispatch) =>
  dispatch({ type: actionTypes.SET_AUTH, payload: authorized });

export const getUser = () => async (dispatch) => {
  try {
    dispatch(setFetching(true));
    const profile = await provider.get(apiEndpoints.PROFILE);
    if (profile) {
      const {
        email: userEmail,
        first_name: firstName,
        last_name: lastName,
        id,
        is_teacher: isTeacher,
      } = profile;
      dispatch({
        type: actionTypes.LOGIN_SUCCESS,
        payload: {
          email: userEmail,
          isTeacher,
          firstName,
          lastName,
          id,
        },
      });
      dispatch(setAuth(true));
      dispatch(setFetching(false));
      return true;
    }
  } catch (error) {
    console.log('error: ', error);
    const { msg } = error.data || {};
    dispatch(setFetching(false));
    dispatch(showSnack({ type: snackTypes.error, text: msg || 'Что-то пошло не так' }));
    throw error;
  }
  dispatch(setFetching(false));
  return false;
};

export const logIn = ({ email, password }) => async (dispatch) => {
  try {
    dispatch(setFetching(true));
    const res = await provider.action(apiEndpoints.LOGIN, { username: email, password });
    if (res) {
      const { token } = res;
      provider.setToken(token);
      const profile = await dispatch(getUser());
      dispatch(setFetching(false));
      return profile;
    }
  } catch (error) {
    console.log('error: ', error);
    const { msg } = error.data || {};
    dispatch(setFetching(false));
    dispatch(showSnack({ type: snackTypes.error, text: msg || 'Что-то пошло не так' }));
    throw error;
  }
  dispatch(setFetching(false));
  return false;
};
