import React, {
  // useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getClaims, setCurrentClaim, getTeachersList } from '../store/modules/selection';

import Scheduler from '../components/TeachersList/Scheduler';
import TeacherCell, { dataLabelVariants } from '../components/TeachersList/TeacherCell';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  tableWrapper: {
    maxHeight: 640,
    overflow: 'auto',
  },
  table: {
    minWidth: 1024,
  },
}));

const tableColumns = [
  {
    id: dataLabelVariants.name,
    label: 'Ф.И.О.',
    minWidth: 240,
  },
  {
    id: dataLabelVariants.contacts,
    label: 'Контакты',
    minWidth: 240,
  },
  {
    id: dataLabelVariants.actions,
    label: 'Действия',
    minWidth: 160,
    align: 'right',
  },
];

const TeachersList = ({
  teachersList = [],
  // tmpGet,
  // tmpSetCurrent,
  // tmpGetTeachers,
}) => {
  // useEffect(() => {
  //   const kek = async () => {
  //     await tmpGet();
  //     await tmpSetCurrent(3);
  //     await tmpGetTeachers({ claim_id: 3 });
  //   };

  //   kek();
  // }, [tmpGet, tmpGetTeachers, tmpSetCurrent]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const updateRowsPerPageValue = (newVal) => {
    setRowsPerPage(+newVal);
    setPage(0);
  };

  const css = useStyles();

  if (teachersList.length === 0) {
    return <Redirect to="/selection" />;
  }

  return (
    <Container maxWidth="lg">
      {teachersList.length > 0 ? (
        <>
          <Paper className={css.paper}>
            <div className={css.tableWrapper}>
              <Table className={css.table} stickyHeader aria-label="teachers list table">
                <TableHead>
                  <TableRow>
                    {tableColumns.map(({
                      id, label, minWidth = 0, align = 'left',
                    }) => (
                      <TableCell align={align} style={{ minWidth }} key={id}>
                        {label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teachersList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((teacher) => (
                      <TableRow hover role="listitem" tabIndex={-1} key={teacher.id}>
                        {tableColumns.map(({ id, align = 'left' }) => (
                          <TableCell key={id} align={align} valign="bottom">
                            <TeacherCell dataLabel={id} dataItem={teacher} />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Отображать по"
              rowsPerPageOptions={[10, 25, 100]}
              count={teachersList.length}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onChangePage={(ev, newPage) => setPage(newPage)}
              onChangeRowsPerPage={({ target: { value } }) => updateRowsPerPageValue(value)}
            />
          </Paper>
          <Scheduler />
        </>
      ) : (
        <Typography variant="h4">Нет преподавателей по заданным фильтрам</Typography>
      )}
    </Container>
  );
};

TeachersList.propTypes = {
  teachersList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // tmpGet: PropTypes.func.isRequired,
  // tmpSetCurrent: PropTypes.func.isRequired,
  // tmpGetTeachers: PropTypes.func.isRequired,
};

const mapStateToProps = ({ selection: { teachersList } }) => ({
  teachersList,
});

const mapDispatchToProps = {
  tmpGet: getClaims,
  tmpSetCurrent: setCurrentClaim,
  tmpGetTeachers: getTeachersList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeachersList);
