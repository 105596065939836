import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import { claimStatus } from '../../utils/models';

const labelTextVariant = Object.freeze({
  [claimStatus.NEW]: 'Новая',
  [claimStatus.ENDED]: 'Завершена',
  [claimStatus.CANCELLED]: 'Отменена',
  [claimStatus.PENDING]: 'На рассмотрении',
});

const useStyle = makeStyles((theme) => ({
  [claimStatus.ENDED]: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.contrastText,
  },
  [claimStatus.CANCELLED]: {
    backgroundColor: theme.palette.error.main,
  },
  [claimStatus.PENDING]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  [claimStatus.NEW]: {
    backgroundColor: green[600],
  },
}));

const StatusChip = ({ status }) => {
  const css = useStyle();
  const propperStatus = typeof status === 'string'
    && Object.values(claimStatus).some((val) => val === status);

  return propperStatus ? (
    <Chip label={labelTextVariant[status]} color="secondary" className={css[status]} />
  ) : (
    <></>
  );
};

StatusChip.propTypes = {
  status: PropTypes.string,
};

StatusChip.defaultProps = {
  status: null,
};

export default StatusChip;
