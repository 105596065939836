/**
 * @description Config creator. Makes fetch config object with inputed token.
 * @param { string } token - API token
 * @param { boolean } formDataConfig - Describes returning config for default request or for
 * FormData.
 */
export const createConfig = (token = false, formDataConfig = false) => {
  const defaultHeaders = {
    ...(formDataConfig ? {} : { 'Content-Type': 'application/json' }),
    Accept: 'application/json',
  };
  const finConfig = {
    headers: {
      ...defaultHeaders,
      ...(token && {
        Authorization: `Token ${token}`,
      }),
    },
  };
  return finConfig;
};

const parseQueryString = (obj) => {
  const queryArr = Object.keys(obj).map(
    (key) => `${key}=${obj[key].toString().replace(/\+/gm, '%2B')}`,
  );
  return `?${queryArr.join('&')}`;
};

/**
 * @description Returns final url for API request
 * @param { Function } getUrl - endpoints "ENUM" url getter
 * @param { any } params - object with api params for provider (query string and path params);
 */
export const processUrl = (getUrl, params) => {
  const { query: queryParams = false, path: pathParams = '' } = params;
  const query = queryParams ? parseQueryString(queryParams) : '';
  const url = getUrl(pathParams);
  return url + query;
};

const parseBody = async (err) => {
  try {
    return await err.json();
  } catch (innerError) {
    return { data: { msg: 'Что-то пошло не так' } };
  }
};

/**
 * @description parsing error data for trycatch stuff
 * @param { any } res - Fetch response
 */
export const parseErrorData = async (res) => {
  const { status } = res;
  const data = await parseBody(res);
  const errBody = {
    res,
    status,
    data: data || { msg: 'Что-то пошло не так' },
  };
  return errBody;
};
