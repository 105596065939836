import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const Name = ({ firstName, lastName, middleName }) => (
  <Typography variant="body1">
    {lastName.length > 0 && (
    <span>
      {lastName}
      &nbsp;
    </span>
    )}
    <span>
      {firstName}
      &nbsp;
    </span>
    {middleName.length > 0 && <span>{middleName}</span>}
  </Typography>
);

Name.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  middleName: PropTypes.string,
};

Name.defaultProps = {
  lastName: '',
  middleName: '',
};

export default Name;
