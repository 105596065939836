import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import StatusChip from './StatusChip';

import { isStudentArbitration } from '../../utils/validators';

const useStyles = makeStyles((theme) => ({
  item: {
    marginRight: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}));

const ExpansionSummary = ({
  id,
  user: { firstName, lastName },
  group: { name, educationTypeName },
  status,
  arbitrationType,
}) => {
  const css = useStyles();

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Grid container alignItems="center">
          <Grid item className={css.item}>
            <Typography variant="body1">
              ID:&nbsp;
              <b>{id}</b>
            </Typography>
          </Grid>
          <Grid item className={css.item}>
            <Typography variant="body1">
              От:&nbsp;
              <b>{firstName}</b>
              &nbsp;
              <b>{lastName}</b>
            </Typography>
          </Grid>
          <Grid item className={css.item}>
            <Typography variant="body2">
              Группа:&nbsp;
              <span>
                {name}
                &nbsp;|&nbsp;
                {educationTypeName}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {arbitrationType && (
          <Chip
            label={isStudentArbitration(arbitrationType) ? 'Слушатель' : 'Преподаватель'}
            color={isStudentArbitration(arbitrationType) ? 'secondary' : 'default'}
            className={css.chip}
          />
        )}
        {typeof status === 'string' && <StatusChip status={status} />}
      </Grid>
    </Grid>
  );
};

ExpansionSummary.propTypes = {
  id: PropTypes.number.isRequired,
  user: PropTypes.exact({
    firstName: PropTypes.string || [null],
    lastName: PropTypes.string || [null],
  }).isRequired,
  group: PropTypes.exact({
    name: PropTypes.string,
    educationTypeName: PropTypes.string,
  }).isRequired,
  status: PropTypes.string,
  arbitrationType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

ExpansionSummary.defaultProps = {
  status: null,
  arbitrationType: false,
};

export default ExpansionSummary;
