import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const linksList = [
  {
    name: 'Арбитраж',
    to: '/arbitration',
  },
  {
    name: 'Подбор преподавателя',
    to: '/selection',
  },
  {
    name: 'Заявки на отпуск',
    to: '/vacation',
  },
  {
    name: 'Мои заявки',
    to: '/my_claims',
  },
];

const Links = ({ css }) => (
  <Grid container alignItems="center" justify="flex-start">
    {linksList.map(({ name, to }) => (
      <Grid item className={cx(css.linkWrap)} key={to}>
        <Link to={to} className={css.linkItem}>{name}</Link>
      </Grid>
    ))}
  </Grid>
);

Links.propTypes = {
  css: PropTypes.objectOf(PropTypes.string),
};

Links.defaultProps = {
  css: {},
};

export default Links;
