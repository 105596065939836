import React from 'react';
import { connect } from 'react-redux';

import { getClaims, setCurrentClaim } from '../store/modules/selection';

import withClaimItemLayout from '../hocs/withClaimItemLayout';
import Claim from '../components/SelectionItem/Claim';

const SelectionItem = () => (
  <Claim />
);

const mapStateToProps = ({ selection: { claimsList, currentClaim, fetchedClaims } }) => ({
  claimsList,
  currentClaim,
  fetchedClaims,
});

const mapDispatchToProps = {
  getClaimsList: getClaims,
  setClaim: setCurrentClaim,
};

export default connect(mapStateToProps, mapDispatchToProps)(withClaimItemLayout(SelectionItem));
