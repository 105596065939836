import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Box, Typography, Link, Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { parseProbableNull } from '../../../utils/validators';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  box: {
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ContactsSection = memo(({ user, manager }) => {
  const css = useStyles();

  const { email, phone } = user;
  const { email: managerEmail, phone: managerPhone } = parseProbableNull(manager);

  return (
    <Paper className={css.root} elevation={2}>
      <Box className={css.box}>
        <Typography variant="h5" gutterBottom>
          Пользователь
        </Typography>
        <Typography variant="body1" gutterBottom>
          <span>E-mail:&nbsp;</span>
          <Link href={`mailto:${email}`} color="secondary">
            {email}
          </Link>
        </Typography>
        {phone !== null && (
          <Typography variant="body1" gutterBottom>
            <span>Телефон:&nbsp;</span>
            <Link href={`tel:${phone}`} color="secondary">
              {phone}
            </Link>
          </Typography>
        )}
        {manager !== null && (
          <>
            <Divider className={css.divider} />
            <Typography variant="h5" gutterBottom>
              Менеджер
            </Typography>
            <Typography variant="body1" gutterBottom>
              <span>E-mail:&nbsp;</span>
              <Link href={`mailto:${managerEmail}`} color="secondary">
                {managerEmail}
              </Link>
            </Typography>
            {managerPhone !== null && (
              <Typography variant="body1" gutterBottom>
                <span>Телефон:&nbsp;</span>
                <Link href={`tel:${managerPhone}`} color="secondary">
                  {managerPhone}
                </Link>
              </Typography>
            )}
          </>
        )}
      </Box>
    </Paper>
  );
});

ContactsSection.propTypes = {
  user: PropTypes.shape().isRequired,
  manager: PropTypes.shape(),
};

ContactsSection.defaultProps = {
  manager: null,
};

export default ContactsSection;
