import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';


const PrivateRoute = ({ children, isAuthorized, ...rest }) => {
  const location = useLocation();
  const isLoginPage = location.pathname.includes('login');

  return (
    <Route
      {...rest}
      render={() => {
        if (isAuthorized) {
          if (isLoginPage) {
            return <Redirect to="/arbitration" />;
          }
          return children;
        }
        if (isLoginPage) {
          return children;
        }
        return <Redirect to={{ pathname: '/login', state: { ...location } }} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth: { isAuthorized } }) => ({ isAuthorized });

export default connect(mapStateToProps)(PrivateRoute);
