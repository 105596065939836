import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper, Table, TableHead, TableCell, TableRow, TableBody,
} from '@material-ui/core';
import { CheckCircle as CheckIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import { genders } from '../../../utils/models';
import { parseProbableNull } from '../../../utils/validators';

import Image from '../../Common/Image';
import subjectIconDefault from '../../../assets/img/course_icon.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

const createItem = (name, value) => ({ name, value });

const createRows = (claimData = {}) => {
  const {
    age_from: ageFrom,
    age_to: ageTo,
    comment,
    gender,
    subject,
    subject_level: subjectLevel,
    with_native_teacher: nativeTeacher,
    teacher_user: teacherUser,
  } = claimData;
  const { name: subjectLevelName } = parseProbableNull(subjectLevel);
  const { name: subjectName, color: subjectColor, icon: subjectIcon } = parseProbableNull(subject);

  const rows = [
    subject !== null
      && createItem(
        'Предмет обучения',
        <span>
          <span style={{ color: `#${subjectColor}` }}>{subjectName}</span>
          <Image
            src={subjectIcon}
            fallback={subjectIconDefault}
            style={{
              width: 16,
              height: 16,
              objectFit: 'contain',
              marginLeft: 6,
            }}
          />
        </span>,
      ),
    typeof gender === 'string' && gender.length > 0 && createItem('Пол', genders[gender]),
    (typeof ageFrom === 'number' || typeof ageTo === 'number')
      && createItem(
        'Возраст',
        <span>
          {typeof ageFrom === 'number' && <span>{`от ${ageFrom} `}</span>}
          {typeof ageTo === 'number' && <span>{`до ${ageTo} `}</span>}
          <span>лет</span>
        </span>,
      ),
    typeof subjectLevelName === 'string'
      && subjectLevelName.length > 0
      && createItem('Уровень владения предметом обучения', subjectLevelName),
    nativeTeacher
      && createItem(
        'Носитель языка',
        <span style={{ color: green[600] }}>
          <CheckIcon color="inherit" />
        </span>,
      ),
    comment.length > 0 && createItem('Комментарий', comment),
    teacherUser !== null
      && createItem('Назначен преподаватель', teacherUser.last_name_and_initials),
  ];
  return rows.filter((el) => el);
};

const TeacherParams = ({ currentClaim }) => {
  const css = useStyles();
  const rows = createRows(currentClaim);

  return (
    <Paper className={css.root} elevation={2}>
      <Table className={css.table} aria-label="teacher params">
        <TableHead>
          <TableRow>
            <TableCell>Параметр</TableCell>
            <TableCell>Значение</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((el) => (
            <TableRow key={el.name}>
              <TableCell>{el.name}</TableCell>
              <TableCell>{el.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

TeacherParams.propTypes = {
  currentClaim: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ selection: { currentClaim } }) => ({ currentClaim });

export default connect(mapStateToProps)(TeacherParams);
