import React from 'react';
import {
  AppBar, Toolbar, Grid, Box, Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Links from './Header/Links';
import User from './Header/User';

import Logo from '../../assets/img/logo.svg';

const useStyles = makeStyles((theme) => ({
  toolbarCont: {
    padding: `${theme.spacing(2)}px 0`,
  },
  menu: {
    width: '50%',
  },
  leftCont: {
    marginRight: theme.spacing(2),
    '& img': {
      maxHeight: 42,
      objectFit: 'contain',
    },
  },
  linkWrap: {
    marginRight: theme.spacing(2),
  },
  linkItem: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Header = () => {
  const css = useStyles();

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar className={css.toolbarCont}>
          <Grid container alignItems="center" className={css.menu}>
            <Grid item className={css.leftCont}>
              <img src={Logo} alt="Alibra" />
            </Grid>
            <Grid item>
              <Links css={css} />
            </Grid>
          </Grid>
          <Box className={css.menu}>
            <User />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
