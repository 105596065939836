export const snackTypes = Object.freeze({
  error: 'error',
  warning: 'warning',
  success: 'success',
});

const initialState = {
  snack: {
    type: 'error',
    text: 'Ошибка',
    id: -1,
  },
  fetching: [],
};

const actionTypes = {
  SHOW_SNACK: 'SHOW_SNACK',
  SET_FETCHING: 'SET_FETCHING',
};

export default (state = initialState, action = { type: null, payload: null }) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SHOW_SNACK: {
      return {
        ...state,
        snack: payload,
      };
    }
    case actionTypes.SET_FETCHING: {
      const { fetching } = state;
      const nextArr = payload ? [...fetching, 'loader'] : fetching.slice(0, fetching.length - 1);
      return {
        ...state,
        fetching: nextArr,
      };
    }
    default: {
      return state;
    }
  }
};

// Actions
export const showSnack = ({ type = snackTypes.error, text = 'Что-то пошло не так' }) => (
  dispatch,
) => {
  const id = Math.random();
  dispatch({ type: actionTypes.SHOW_SNACK, payload: { type, text, id } });
};

export const setFetching = (isFetching = true) => (dispatch) =>
  dispatch({ type: actionTypes.SET_FETCHING, payload: isFetching });
