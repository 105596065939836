import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[700],
    color: 'white',
  },
});

const createSub = (str = '') => (typeof str === 'string' && str.length > 0 ? [...str][0] : '');

const createInitials = (firstName = '', lastName = '') => {
  const first = createSub(firstName);
  const last = createSub(lastName);
  return first + last;
};

const ManagersSelect = ({ managersList = [], fetchedManagersList, onSelectManager }) => {
  const [showDialog, setShowDialog] = useState(false);
  const css = useStyles();

  const handleSelect = (id) => {
    setShowDialog(false);
    onSelectManager(id);
  };

  return (
    fetchedManagersList
    && managersList.length > 0 && (
      <>
        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          aria-labelledby="managers-dialog-title"
        >
          <DialogTitle id="managers-dialog-title">Выберите менеджера</DialogTitle>
          <List>
            {managersList.map(
              ({
                last_name_and_initials: fullName,
                first_name: firstName,
                last_name: lastName,
                id,
              }) => (
                <ListItem button onClick={() => handleSelect(id)} key={id}>
                  <ListItemAvatar>
                    <Avatar className={css.avatar}>{createInitials(firstName, lastName)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={fullName.length > 2 ? fullName : firstName} />
                </ListItem>
              ),
            )}
          </List>
        </Dialog>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setShowDialog(true)}>
            Назначить менеджера
          </Button>
        </Grid>
      </>
    )
  );
};

ManagersSelect.propTypes = {
  managersList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchedManagersList: PropTypes.bool.isRequired,
  onSelectManager: PropTypes.func.isRequired,
};

const mapStateToProps = ({ managers: { fetchedManagersList, managersList } }) => ({
  managersList,
  fetchedManagersList,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManagersSelect);
