import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';

import createStore from './store';
import theme from './theme';

import Layout from './Layout';
import PrivateRoute from './components/Common/PrivateRoute';
import Arbitration from './pages/Arbitration';
import ArbitrationItem from './pages/ArbitrationItem';
import Login from './pages/Login';
import Selection from './pages/Selection';
import SelectionItem from './pages/SelectionItem';
import TeachersList from './pages/TeachersList';
import UserClaims from './pages/UserClaims';
import Vacation from './pages/Vacation';

import { setAuth, getUser } from './store/modules/auth';
import { getManagers } from './store/modules/managers';
import { provider } from './utils/api';

const store = createStore();

class App extends Component {
  async componentDidMount() {
    const token = window.localStorage.getItem('ALIBRA_MANAGER_TOKEN');
    provider.setToken(token !== null && token);
    const hasToken = token !== null && typeof token === 'string';
    if (hasToken) {
      await store.dispatch(getUser());
      await store.dispatch(getManagers());
      store.dispatch(setAuth(hasToken));
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Layout>
              <Switch>
                <Route exact path="/login">
                  <Login />
                </Route>
                <PrivateRoute path="/arbitration/:id">
                  <ArbitrationItem />
                </PrivateRoute>
                <PrivateRoute path="/arbitration">
                  <Arbitration />
                </PrivateRoute>
                <PrivateRoute path="/my_claims">
                  <UserClaims />
                </PrivateRoute>
                <PrivateRoute path="/selection/:id">
                  <SelectionItem />
                </PrivateRoute>
                <PrivateRoute path="/selection">
                  <Selection />
                </PrivateRoute>
                <PrivateRoute path="/teachers_list">
                  <TeachersList />
                </PrivateRoute>
                <PrivateRoute path="/vacation">
                  <Vacation />
                </PrivateRoute>
                <Redirect to="/arbitration" />
              </Switch>
            </Layout>
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    );
  }
}

export default App;
