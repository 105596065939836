import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Box, Divider, Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { parseProbableNull } from '../../utils/validators';

import Image from '../Common/Image';
import defaultCourseIcon from '../../assets/img/course_icon.png';

const useStyles = makeStyles((theme) => ({
  title: {
    width: '33%',
    paddingRight: theme.spacing(4),
  },
  value: {
    width: '67%',
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const ClaimData = ({
  user,
  course,
  studentsGroup,
  managerUser,
  plannedDate,
}) => {
  const css = useStyles();

  const { first_name: firstName, last_name: lastName } = parseProbableNull(user);

  const {
    name: groupName,
    education_type_name: groupEducationTypeName,
    type_name: groupTypeName,
  } = parseProbableNull(studentsGroup);

  const { subject: courseSubject, name: courseName, color = '' } = parseProbableNull(course);

  const { name: subjectName, icon: subjectIcon } = parseProbableNull(courseSubject);

  const {
    first_name: managerFirst,
    last_name: managerLast,
    email: managerEmail,
  } = parseProbableNull(managerUser);

  return (
    <Box>
      {user !== null && (
        <>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item className={css.title}>
              <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                Создана
              </Typography>
            </Grid>
            <Grid item className={css.value}>
              <Box>
                <>
                  <Typography variant="body1">
                    <span>{firstName}</span>
                    &nbsp;
                    <span>{lastName}</span>
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <Typography
                      variant="body1"
                      style={{ color: `#${color}`, fontWeight: 700 }}
                      component="span"
                    >
                      {courseName}
                    </Typography>
                    <span>,&nbsp;</span>
                    <span>{subjectName}</span>
                    <Image
                      src={subjectIcon}
                      fallback={defaultCourseIcon}
                      style={{
                        width: 16,
                        height: 16,
                        objectFit: 'contain',
                        marginLeft: 6,
                      }}
                    />
                  </Typography>
                </>
                {studentsGroup !== null && (
                  <>
                    <Typography variant="body1">
                      <span>Группа&nbsp;&mdash;&nbsp;</span>
                      <b>{groupName}</b>
                      &nbsp;
                      <span>{groupTypeName}</span>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <span>Тип обучения&nbsp;&mdash;&nbsp;</span>
                      <span>{groupEducationTypeName}</span>
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Divider className={css.divider} />
        </>
      )}
      {plannedDate !== null && (
        <>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item className={css.title}>
              <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                Желаемая дата начала обучения
              </Typography>
            </Grid>
            <Grid item className={css.value}>
              <Box>
                <Typography variant="body1">{plannedDate}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider className={css.divider} />
        </>
      )}
      {managerUser !== null && (
        <>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item className={css.title}>
              <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                Менеджер
              </Typography>
            </Grid>
            <Grid item className={css.value}>
              <Box>
                <Typography variant="body1" gutterBottom>
                  <span>{managerFirst}</span>
                  &nbsp;
                  <span>{managerLast}</span>
                </Typography>
                {typeof managerEmail === 'string' && (
                  <Link href={`mailto:${managerEmail}`} color="secondary">Связаться</Link>
                )}
              </Box>
            </Grid>
          </Grid>
          <Divider className={css.divider} />
        </>
      )}
    </Box>
  );
};

ClaimData.propTypes = {
  user: PropTypes.shape(),
  course: PropTypes.shape(),
  studentsGroup: PropTypes.shape(),
  managerUser: PropTypes.shape(),
  plannedDate: PropTypes.string,
};

ClaimData.defaultProps = {
  user: {},
  course: {},
  studentsGroup: {},
  managerUser: {},
  plannedDate: null,
};

export default ClaimData;
