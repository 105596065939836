import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment-timezone';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { Typography, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { blue, pink, teal } from '@material-ui/core/colors';

import { educationTypes } from '../../../utils/models';

const styles = () => ({
  appointment: {
    backgroundColor: blue[200],
    pointerEvents: 'none',
  },
  offline: {
    backgroundColor: pink[200],
  },
  both: {
    backgroundColor: teal[400],
  },
});

const AppointmentRaw = memo(({ classes, data, ...restProps }) => (
  <Appointments.Appointment
    data={data}
    className={cx(classes.appointment, classes[data.type])}
    {...restProps}
  />
));

AppointmentRaw.propTypes = {
  classes: PropTypes.shape(),
  data: PropTypes.shape().isRequired,
};

AppointmentRaw.defaultProps = {
  classes: {},
};

export const Appointment = withStyles(styles, { name: 'Appointment' })(AppointmentRaw);

const getTime = (date) => moment(date).format('HH:mm');

const AppointmentContentRaw = memo(({ classes, data, ...restProps }) => {
  const { startDate, endDate, type } = data;

  return (
    <Appointments.AppointmentContent
      data={data}
      className={cx(classes.appointment, classes[data.type])}
      {...restProps}
    >
      <Box>
        <Typography variant="subtitle1">
          <span>{getTime(startDate)}</span>
          &nbsp;&mdash;
          {' '}
          <span>{getTime(endDate)}</span>
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          {educationTypes[type].humanName}
        </Typography>
      </Box>
    </Appointments.AppointmentContent>
  );
});

AppointmentContentRaw.propTypes = {
  classes: PropTypes.shape(),
  data: PropTypes.shape().isRequired,
};

AppointmentContentRaw.defaultProps = {
  classes: {},
};

export const AppointmentContent = withStyles(styles, { name: 'Appointment' })(
  AppointmentContentRaw,
);
