import moment from 'moment-timezone';
import { provider, apiEndpoints } from '../../../utils/api';
import { setFetching, showSnack, snackTypes } from '../common';
import { claimStatus } from '../../../utils/models';
import { parseProbableNull } from '../../../utils/validators';

const initialState = {
  fetchedClaims: false,
  claimsList: [],
  currentClaim: false,
  myClaims: [],
  teachersList: [],
  scheduler: {
    showScheduler: false,
    teacherId: null,
    teacherInstance: null,
    intervals: null,
  },
};

const actionTypes = {
  GET_CLAIMS: 'GET_CLAIMS',
  SET_CURRENT_CLAIM: 'SET_CURRENT_CLAIM',
  EDIT_TEACHER_SELECTION_CLAIM: 'EDIT_TEACHER_SELECTION_CLAIM',
  GET_TEACHERS_LIST: 'GET_TEACHERS_LIST',
  SET_TEACHER_FOR_CLAIM: 'SET_TEACHER_FOR_SELECTION_CLAIM',
  EDIT_SCHEDULER_STATE: 'EDIT_SCHEDULER_STATE',
};

export default (state = initialState, action = { type: null, payload: null }) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_CLAIMS: {
      const { claims, id: userId = false } = payload;
      const managersClaims = userId
        ? claims.filter(
          ({ manager_user: managerUser }) => parseProbableNull(managerUser).id === userId,
        )
        : [];
      return {
        ...state,
        claimsList: claims,
        fetchedClaims: true,
        myClaims: managersClaims,
      };
    }
    case actionTypes.SET_CURRENT_CLAIM: {
      const { claimsList } = state;
      const currentClaim = claimsList.find(({ id }) => id === payload) || false;
      return {
        ...state,
        currentClaim,
      };
    }
    case actionTypes.EDIT_TEACHER_SELECTION_CLAIM: {
      const {
        claimsList = [],
        currentClaim: { id: currentClaimId },
      } = state;
      const { id } = payload;
      const newClaims = [...claimsList.filter(({ id: claimId }) => claimId !== id), payload].sort(
        (a, b) => b.id - a.id,
      );
      const currentClaim = currentClaimId === id ? payload : state.currentClaim;
      return {
        ...state,
        currentClaim,
        claimsList: newClaims,
      };
    }
    case actionTypes.GET_TEACHERS_LIST: {
      return {
        ...state,
        teachersList: payload,
      };
    }
    case actionTypes.SET_TEACHER_FOR_CLAIM: {
      return {
        ...state,
      };
    }
    case actionTypes.EDIT_SCHEDULER_STATE: {
      const { scheduler } = state;
      const newScheduler = {
        ...scheduler,
        ...payload,
      };
      return {
        ...state,
        scheduler: newScheduler,
      };
    }
    default: {
      return state;
    }
  }
};

// Actions
export const getClaims = () => async (dispatch, getState) => {
  try {
    dispatch(setFetching(true));
    const res = await provider.get(apiEndpoints.TEACHER_SELECTION);
    if (res) {
      const {
        auth: { user },
      } = getState();
      dispatch({
        type: actionTypes.GET_CLAIMS,
        payload: { claims: res, id: parseProbableNull(user).id },
      });
      dispatch(setFetching(false));
      return res;
    }
  } catch (error) {
    console.log('error: ', error);
    const { msg } = error.data || {};
    dispatch(setFetching(false));
    dispatch(showSnack({ type: snackTypes.error, text: msg || 'Что-то пошло не так' }));
    throw error;
  }
  dispatch(setFetching(false));
  return false;
};

export const setCurrentClaim = (id) => (dispatch) =>
  dispatch({ type: actionTypes.SET_CURRENT_CLAIM, payload: id });

export const editTeacherSelectionClaim = (id, body = {}) => async (dispatch) => {
  try {
    dispatch(setFetching(true));
    const res = await provider.action(apiEndpoints.TEACHER_SELECTION, body, 'PATCH', { path: id });
    if (res) {
      dispatch({ type: actionTypes.EDIT_TEACHER_SELECTION_CLAIM, payload: res });
      dispatch(setFetching(false));
      return res;
    }
  } catch (error) {
    console.log('error: ', error);
    const { msg } = error.data || {};
    dispatch(setFetching(false));
    dispatch(showSnack({ type: snackTypes.error, text: msg || 'Что-то пошло не так' }));
    throw error;
  }
  dispatch(setFetching(false));
  return false;
};

export const getTeachersList = (query = {}) => async (dispatch) => {
  try {
    dispatch(setFetching(true));
    const res = await provider.get(apiEndpoints.GET_TEACHERS, { query });
    if (res) {
      dispatch({ type: actionTypes.GET_TEACHERS_LIST, payload: res });
      dispatch(setFetching(false));
      return res;
    }
  } catch (error) {
    console.log('error: ', error);
    const { msg } = error.data || {};
    dispatch(setFetching(false));
    dispatch(showSnack({ type: snackTypes.error, text: msg || 'Что-то пошло не так' }));
    throw error;
  }
  dispatch(setFetching(false));
  return false;
};

export const setTeacherForGroup = (groupId, teacherId) => async (dispatch, getState) => {
  try {
    const {
      selection: {
        currentClaim: { id: claimId },
      },
    } = getState();
    dispatch(setFetching(true));
    const res = await provider.action(apiEndpoints.SET_TEACHER, {
      group_id: groupId,
      teacher_user_id: teacherId,
      claim_id: claimId,
    });
    if (res) {
      const {
        selection: {
          currentClaim: { id },
        },
      } = getState();
      await dispatch(editTeacherSelectionClaim(id, { status: claimStatus.ENDED }));
      dispatch({ type: actionTypes.SET_TEACHER_FOR_CLAIM, payload: res });
      dispatch(setFetching(false));
      dispatch(showSnack({ type: snackTypes.success, text: 'Преподаватель успешно назначен' }));
      return true;
    }
  } catch (error) {
    console.log('error: ', error);
    const { msg } = error.data || {};
    dispatch(setFetching(false));
    dispatch(showSnack({ type: snackTypes.error, text: msg || 'Что-то пошло не так' }));
    throw error;
  }
  dispatch(setFetching(false));
  return false;
};

export const editSchedulerState = (patch = {}) => (dispatch) =>
  dispatch({ type: actionTypes.EDIT_SCHEDULER_STATE, payload: patch });

export const getTeacherSelectionSchedulerData = (teacherId) => async (dispatch, getState) => {
  try {
    const {
      selection: {
        scheduler: { teacherId: currentTeacherId },
      },
    } = getState();
    if (currentTeacherId === teacherId) {
      return dispatch(
        editSchedulerState({
          showScheduler: true,
        }),
      );
    }
    dispatch(setFetching(true));
    const fromDate = moment().format();
    const toDate = moment()
      .add(1, 'month')
      .format();
    const res = await provider.get(apiEndpoints.INTERVALS, {
      query: {
        teacher_user_id: teacherId,
        from_datetime: fromDate,
        to_datetime: toDate,
      },
    });
    if (res) {
      const {
        selection: { teachersList = [] },
      } = getState();
      const teacherInstance = teachersList.find(({ id }) => id === teacherId);
      dispatch(setFetching(false));
      dispatch(
        editSchedulerState({
          showScheduler: true,
          intervals: res,
          teacherId,
          teacherInstance,
        }),
      );
      return true;
    }
  } catch (error) {
    console.log('error: ', error);
    const { msg } = error.data || {};
    dispatch(setFetching(false));
    dispatch(showSnack({ type: snackTypes.error, text: msg || 'Что-то пошло не так' }));
    throw error;
  }
  dispatch(setFetching(false));
  return false;
};
