import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Input,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { setLoginData, logIn } from '../store/modules/auth';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(12),
  },
  paper: {
    padding: theme.spacing(8),
  },
  inputWrap: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
}));

const Login = ({
  isAuthorized, loginData, setLoginData: passDataToRedux, logIn: onLogIn,
}) => {
  const css = useStyles();
  const location = useLocation();
  const { pathname = '/arbitration' } = location.state || {};

  if (isAuthorized) return <Redirect to={pathname} />;

  return (
    <Box onKeyDown={({ keyCode }) => keyCode === 13 && onLogIn(loginData)}>
      <Container maxWidth="sm" className={css.container}>
        <Paper elevation={3} className={css.paper}>
          <Grid container direction="column" component="form">
            {Object.entries(loginData).map(([name, value], index) => (
              <Grid item className={css.inputWrap} key={name}>
                <FormControl variant="standard" fullWidth autoFocus={index === 0} required>
                  <InputLabel htmlFor={name} color="secondary">
                    {name === 'email' ? 'E-mail' : 'Пароль'}
                  </InputLabel>
                  <Input
                    type={name}
                    id={name}
                    color="secondary"
                    value={value}
                    autoComplete={name === 'email' ? 'email' : 'current-password'}
                    onChange={({ currentTarget: { value: inputValue } }) =>
                      passDataToRedux({ [name]: inputValue })}
                  />
                </FormControl>
              </Grid>
            ))}
            <Grid>
              <Button variant="contained" color="secondary" onClick={() => onLogIn(loginData)}>
                Войти
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

Login.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  loginData: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  setLoginData: PropTypes.func.isRequired,
  logIn: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { isAuthorized, loginData } }) => ({
  isAuthorized,
  loginData,
});

const mapDispatchToProps = {
  setLoginData,
  logIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
