const initialState = {
  show: false,
  test: 'kek',
};

const actionTypes = {
  SET_TEST: 'SET_TEST',
};

export default (state = initialState, action = { type: null, payload: null }) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_TEST: {
      return {
        ...state,
        test: payload,
      };
    }
    default: {
      return state;
    }
  }
};

// Actions
export const toggleFaq = (newTest = 'test') => (dispatch) => {
  console.log('Some async action_____START');
  setTimeout(() => {
    console.log('Some async action_____DONE');
    return dispatch({ type: actionTypes.SET_TEST, payload: newTest });
  }, 3000);
};
