import { provider, apiEndpoints } from '../../../utils/api';
import { setFetching, showSnack, snackTypes } from '../common';
import { parseProbableNull } from '../../../utils/validators';

const initialState = {
  fetchedClaims: false,
  claimsList: [],
  myClaims: [],
  currentClaim: false,
};

const actionTypes = {
  GET_CLAIMS: 'GET_ARBITRATION_CLAIMS',
  SET_CURRENT_CLAIM: 'SET_CURRENT_ARBITRATION_CLAIM',
  EDIT_ARBITRATION_CLAIM: 'EDIT_ARBITRATION_CLAIM',
};

export default (state = initialState, action = { type: null, payload: null }) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_CLAIMS: {
      const { claims, id: userId = false } = payload;
      const managersClaims = userId
        ? claims.filter(
          ({ manager_user: managerUser }) => parseProbableNull(managerUser).id === userId,
        )
        : [];
      return {
        ...state,
        claimsList: claims,
        fetchedClaims: true,
        myClaims: managersClaims,
      };
    }
    case actionTypes.SET_CURRENT_CLAIM: {
      const { claimsList } = state;
      const currentClaim = claimsList.find(({ id }) => id === payload) || false;
      return {
        ...state,
        currentClaim,
      };
    }
    case actionTypes.EDIT_ARBITRATION_CLAIM: {
      const {
        claimsList = [],
        currentClaim: { id: currentClaimId },
      } = state;
      const { id } = payload;
      const newClaims = [...claimsList.filter(({ id: claimId }) => claimId !== id), payload].sort(
        (a, b) => b.id - a.id,
      );
      const currentClaim = currentClaimId === id ? payload : state.currentClaim;
      return {
        ...state,
        currentClaim,
        claimsList: newClaims,
      };
    }
    default: {
      return state;
    }
  }
};

// Actions
export const getArbitrationClaims = () => async (dispatch, getState) => {
  try {
    dispatch(setFetching(true));
    const res = await provider.get(apiEndpoints.ARBITRATION);
    if (res) {
      const {
        auth: { user },
      } = getState();
      dispatch({
        type: actionTypes.GET_CLAIMS,
        payload: { claims: res, id: parseProbableNull(user).id },
      });
      dispatch(setFetching(false));
      return res;
    }
  } catch (error) {
    console.log('error: ', error);
    const { msg } = error.data || {};
    dispatch(setFetching(false));
    dispatch(showSnack({ type: snackTypes.error, text: msg || 'Что-то пошло не так' }));
    throw error;
  }
  dispatch(setFetching(false));
  return false;
};

export const setCurrentClaim = (id) => (dispatch) =>
  dispatch({ type: actionTypes.SET_CURRENT_CLAIM, payload: id });

export const editArbitrationClaim = (id, body = {}) => async (dispatch) => {
  try {
    dispatch(setFetching(true));
    const res = await provider.action(apiEndpoints.ARBITRATION, body, 'PATCH', { path: id });
    if (res) {
      dispatch({ type: actionTypes.EDIT_ARBITRATION_CLAIM, payload: res });
      dispatch(setFetching(false));
      return res;
    }
  } catch (error) {
    console.log('error: ', error);
    const { msg } = error.data || {};
    dispatch(setFetching(false));
    dispatch(showSnack({ type: snackTypes.error, text: msg || 'Что-то пошло не так' }));
    throw error;
  }
  dispatch(setFetching(false));
  return false;
};
