import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Link } from '@material-ui/core';

const Contacts = ({ phone, email }) => (
  <>
    {phone !== null && (
      <Typography variant="body1" gutterBottom={email !== null}>
        <span>Телефон:&nbsp;</span>
        <Link href={`tel:${phone}`} color="secondary">{phone}</Link>
      </Typography>
    )}
    {email !== null && (
      <Typography variant="body1">
        <span>Email:&nbsp;</span>
        <Link href={`mailto:${email}`} color="secondary">{email}</Link>
      </Typography>
    )}
  </>
);

Contacts.propTypes = {
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null]).isRequired]),
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null]).isRequired]),
};

Contacts.defaultProps = {
  phone: null,
  email: null,
};

export default Contacts;
