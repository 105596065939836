import React, { memo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import { withStyles } from '@material-ui/core';

const classesType = PropTypes.shape({});
const defaultClasses = {};

const styles = (theme) => ({
  tableCell: {
    background: 'rgba(0, 0, 0, 0)',
    '&:hover, &:focus, &:active': {
      background: 'rgba(0, 0, 0, 0)',
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  timeScaleLayout: {
    height: '100%',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  timeScaleLabel: {
    '& >span': {
      fontWeight: 700,
    },
  },
  dayCell: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
  },
  dayCellToday: {
    backgroundColor: theme.palette.secondary.light,
    color: '#ffffff',
    '& *': {
      color: '#ffffff',
    },
  },
});

// Day Cell
const TimeTableCellRaw = memo(({ classes, ...props }) => (
  <WeekView.TimeTableCell className={classes.tableCell} {...props} />
));

TimeTableCellRaw.propTypes = {
  classes: classesType,
};

TimeTableCellRaw.defaultProps = {
  classes: defaultClasses,
};

export const TimeTableCell = withStyles(styles, { name: 'TimetableCell' })(TimeTableCellRaw);

const TimeScaleLayoutRaw = memo(({ classes, ...props }) => (
  <WeekView.TimeScaleLayout className={cx([classes.timeScaleLayout])} {...props} />
));

TimeScaleLayoutRaw.propTypes = {
  classes: classesType,
};

TimeScaleLayoutRaw.defaultProps = {
  classes: defaultClasses,
};

export const TimeScaleLayout = withStyles(styles, { name: 'TimeScaleLayout' })(TimeScaleLayoutRaw);

const TimeScaleLabelRaw = memo(({ classes, ...props }) => (
  <WeekView.TimeScaleLabel className={cx([classes.timeScaleLabel])} {...props} />
));

TimeScaleLabelRaw.propTypes = {
  classes: classesType,
};

TimeScaleLabelRaw.defaultProps = {
  classes: defaultClasses,
};

export const TimeScaleLabel = withStyles(styles, { name: 'TimeScaleLabel' })(TimeScaleLabelRaw);

const DayScaleCellRaw = memo(({ classes, today, ...restProps }) => {
  const classNames = [classes.dayCell, today && classes.dayCellToday];
  return <WeekView.DayScaleCell className={cx(classNames)} {...restProps} />;
});

DayScaleCellRaw.propTypes = {
  classes: classesType,
  today: PropTypes.bool,
};

DayScaleCellRaw.defaultProps = {
  classes: defaultClasses,
  today: false,
};

export const DayScaleCell = withStyles(styles, { name: 'DayCell' })(DayScaleCellRaw);
