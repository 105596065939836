import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: {
    main: '#e8ecff',
    contrastText: '#000000',
  },
  secondary: {
    main: '#568FEB',
    contrastText: '#ffffff',
  },
};
const themeName = 'ALibra Manager';

export default createMuiTheme({ palette, themeName });
