import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  setTeacherForGroup,
  getTeacherSelectionSchedulerData,
} from '../../../store/modules/selection';

const useStyles = makeStyles((theme) => ({
  btn: {
    marginLeft: theme.spacing(2),
  },
}));

const Actions = ({
  id, currentClaimGroup, history, setTeacher, getSchedulerData,
}) => {
  const css = useStyles();

  const showSchedule = async () => {
    await getSchedulerData(id);
  };

  const onSetTeacherClick = async () => {
    const { id: groupId } = currentClaimGroup;
    const res = await setTeacher(groupId, id);
    if (res) {
      history.push('/selection');
    }
  };

  return (
    <Box>
      <Button
        className={css.btn}
        size="small"
        variant="text"
        color="default"
        onClick={showSchedule}
      >
        Посмотреть расписание
      </Button>
      <Button
        className={css.btn}
        size="small"
        variant="contained"
        color="secondary"
        onClick={onSetTeacherClick}
      >
        Назначить
      </Button>
    </Box>
  );
};

Actions.propTypes = {
  id: PropTypes.number.isRequired,
  currentClaimGroup: PropTypes.shape().isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setTeacher: PropTypes.func.isRequired,
  getSchedulerData: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  selection: {
    currentClaim: { students_group: currentClaimGroup },
  },
}) => ({ currentClaimGroup });

const mapDispatchToProps = {
  setTeacher: setTeacherForGroup,
  getSchedulerData: getTeacherSelectionSchedulerData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Actions));
