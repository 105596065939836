import { createConfig, parseErrorData, processUrl } from './helpers';

const getApiUrl = (urlGetter, params) => {
  const apiBase = process.env.REACT_APP_API_URL;
  const processedUrl = processUrl(urlGetter, params);
  return apiBase + processedUrl;
};

class ApiProvider {
  constructor() {
    this.token = false;
    this.config = {};
  }

  setToken(token = false) {
    this.token = token;
    this.config = createConfig(token);
    if (token) {
      window.localStorage.setItem('ALIBRA_MANAGER_TOKEN', token);
    }
  }

  // API CALL methods
  async handleRes(apiUrl, method, payload = false, useDefaults = true) {
    const config = useDefaults ? this.config : this.formDataConfig;
    const body = useDefaults ? JSON.stringify(payload) : payload;
    const fetchRes = await fetch(apiUrl, {
      method,
      ...(payload && { body }),
      ...config,
      credentials: 'include',
    }).then(async (res) => {
      if (!res.ok) {
        const errBody = await parseErrorData(res.clone());
        throw errBody;
      } else {
        const resData = await res.json();
        return resData;
      }
    });
    return fetchRes;
  }

  /**
   * @description Makes POST | ${method} action with '/apiUrl/${endpoint}'
   * @param { String } endpoint - EDNPOINT enum for API url
   * @param { Object } payload - payload which will be send in post body
   * @param { String } method - request method (POST === default, PUT, PATCH)
   * @param { { query: {}, path: String } } params - Object with query and path  params.
   */
  async action(
    endpoint = false,
    payload = {},
    method = 'POST',
    params = { query: false, path: '' },
  ) {
    if (!endpoint) throw new Error('Url not found!');
    const apiUrl = getApiUrl(endpoint, params);
    const res = await this.handleRes(apiUrl, method, payload);
    return res;
  }

  /**
   * @description Makes GET request with /apiUrl/${endpoint}.
   * @param { String } endpoint - EDNPOINT enum for API url
   * @param { { query: {}, path: String } } params - Object with query and path  params.
   */
  async get(endpoint = false, params = { query: false, path: '' }) {
    if (!endpoint) throw new Error('URL not found!');
    const apiUrl = getApiUrl(endpoint, params);
    const res = await this.handleRes(apiUrl, 'GET');
    return res;
  }
}

export const provider = new ApiProvider();
export { default as apiEndpoints } from './endpoints';
