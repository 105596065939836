import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Box, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  box: {
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Logs = memo(({ data = [] }) => {
  const css = useStyles();

  return (
    <Paper className={css.root} elevation={2}>
      {data.length > 0 ? (
        <Box className={css.box}>
          <div>logs</div>
        </Box>
      ) : (
        <Box className={css.box}>
          <Typography variant="body1">Логи отсутствуют</Typography>
        </Box>
      )}
    </Paper>
  );
});

Logs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Logs;
