import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import {
  Paper, Typography, Container, Tabs, Tab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getArbitrationClaims } from '../store/modules/arbitration';
import { getClaims } from '../store/modules/selection';

import ClaimsList from '../components/UserClaims/ClaimsList';

const createPromise = (clb = false) =>
  new Promise((resolve, reject) => {
    try {
      clb();
      resolve(clb);
    } catch (error) {
      reject(error);
    }
  });

const useStyles = makeStyles((theme) => ({
  paperCont: {
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tabsPaper: {
    marginTop: theme.spacing(2),
  },
}));

const UserClaims = ({
  fetchedArbitration,
  fetchedSelection,
  arbitrationClaims,
  selectionClaims,
  fetchArbitrationClaims,
  fetchSelectionClaims,
}) => {
  const [hasData, setData] = useState(fetchedSelection && fetchedArbitration);
  const [activeTab, setTab] = useState('arbitration');
  // initial fetch data
  useEffect(() => {
    const fetchData = async () => {
      const calls = [
        async () => !fetchedArbitration && fetchArbitrationClaims(),
        async () => !fetchedSelection && fetchSelectionClaims(),
      ];
      const promises = calls.map((call) => createPromise(call));

      await Promise.all(promises);
    };

    if (!hasData) {
      setData(true);
      fetchData();
    }
  }, [
    hasData,
    setData,
    fetchedSelection,
    fetchedArbitration,
    fetchArbitrationClaims,
    fetchSelectionClaims,
  ]);
  const css = useStyles();

  const handleTabChange = (ev, nextTab) => setTab(nextTab);

  return (
    <Container maxWidth="lg">
      <Paper className={css.paperCont}>
        <Typography variant="h2">Мои заявки</Typography>
        <Paper className={cx(css.tabsPaper, css.paperCont)} elevation={2}>
          <Tabs
            value={activeTab}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleTabChange}
          >
            <Tab label="Арбитраж" value="arbitration" disabled={arbitrationClaims.length === 0} />
            <Tab
              label="Подбор преподавателя"
              value="selection"
              disabled={selectionClaims.length === 0}
            />
          </Tabs>
          {activeTab === 'arbitration' ? (
            <ClaimsList claimsArr={arbitrationClaims} type="arbitration" />
          ) : (
            <ClaimsList claimsArr={selectionClaims} type="selection" />
          )}
        </Paper>
      </Paper>
    </Container>
  );
};

const claimsType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.shape),
  PropTypes.oneOf([null]),
]);
const fetchedBoolType = PropTypes.bool;

UserClaims.propTypes = {
  arbitrationClaims: claimsType.isRequired,
  selectionClaims: claimsType.isRequired,
  fetchedArbitration: fetchedBoolType.isRequired,
  fetchedSelection: fetchedBoolType.isRequired,
  fetchSelectionClaims: PropTypes.func.isRequired,
  fetchArbitrationClaims: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  arbitration: { myClaims: arbitrationClaims, fetchedClaims: fetchedArbitration },
  selection: { myClaims: selectionClaims, fetchedClaims: fetchedSelection },
}) => ({
  arbitrationClaims,
  selectionClaims,
  fetchedArbitration,
  fetchedSelection,
});

const mapDispatchToProps = {
  fetchSelectionClaims: getClaims,
  fetchArbitrationClaims: getArbitrationClaims,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserClaims);
