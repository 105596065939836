import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import * as reducers from './modules';

const getMiddlewares = (mode = process.env.NODE_ENV) => {
  const defaultMiddlewares = [thunkMiddleware];
  const logger = createLogger({ collapsed: true });
  return mode === 'production'
    ? [...defaultMiddlewares]
    : [...defaultMiddlewares, logger];
};

export const initStore = (initialState = {}) => {
  const reducer = combineReducers({ ...reducers });
  const middlewares = getMiddlewares(process.env.NODE_ENV);

  return createStore(reducer, initialState, applyMiddleware(...middlewares));
};


export default function (initialState = {}) {
  const reducer = combineReducers({ ...reducers });
  const middlewares = getMiddlewares(process.env.NODE_ENV);

  return createStore(reducer, initialState, applyMiddleware(...middlewares));
}
