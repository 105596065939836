import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Paper, Typography, Table, TableRow, TableCell, TableBody, Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';

import { parseProbableNull } from '../../../utils/validators';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  box: {
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));

const createRow = (name = '', value = false) => {
  switch (name) {
    case 'id': {
      return {
        name: 'ID лекции:',
        value,
      };
    }
    case 'is_online': {
      return {
        name: 'Онлайн занятие:',
        value: value && (
          <span style={{ color: green[600] }}>
            <CheckCircle color="inherit" />
          </span>
        ),
      };
    }
    case 'start_datetime': {
      return {
        name: 'Время начала:',
        value: moment(value).format('DD.MM.YYYY, HH:mm'),
      };
    }
    case 'end_datetime': {
      return {
        name: 'Время окончания:',
        value: moment(value).format('DD.MM.YYYY, HH:mm'),
      };
    }
    case 'duration': {
      return {
        name: 'Длительность:',
        value: `${value} минут`,
      };
    }
    case 'teacher_user': {
      const { last_name_and_initials: initials, email } = parseProbableNull(value);

      return {
        name: 'Преподаватель:',
        value: value !== null && (
          <Link href={`mailto:${email}`} color="secondary">
            {initials}
          </Link>
        ),
      };
    }
    default: {
      return false;
    }
  }
};

const createRows = ({ bbb_logs: logs, ...restData }) => {
  const entries = Object.entries(restData);
  const rows = entries.map(([name, value]) => createRow(name, value));
  return rows.filter(({ name, value }) => name && value);
};

const Description = memo(({ data }) => {
  const css = useStyles();
  const rows = createRows(data);

  return (
    <Paper className={css.root} elevation={2}>
      <Table className={css.table} aria-label="event data">
        <TableBody>
          {rows.map(({ name, value }) => (
            <TableRow key={name}>
              <TableCell>
                <Typography variant="body1">{name}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{value}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
});

Description.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default Description;
