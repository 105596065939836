import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Image from '../Common/Image';

import defaultCourseIcon from '../../assets/img/course_icon.png';

const useStyles = makeStyles((theme) => ({
  item: {
    minWidth: '40%',
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
}));

const ExpansionDetails = ({
  comment,
  courseName,
  subjectName,
  plannedDate,
  courseIcon,
  teacherUser,
}) => {
  const css = useStyles();

  return (
    <Box style={{ width: '100%' }}>
      <Grid
        container
        justify="flex-start"
        alignItems="flex-start"
        wrap="wrap"
        alignContent="flex-start"
      >
        <Grid item className={css.item}>
          <Typography variant="subtitle2" component="span">
            Заявка по курсу:&nbsp;
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>
              {subjectName}
              ,&nbsp;
            </b>
            <span style={{ whiteSpace: 'nowrap' }}>
              {courseName}
              <Image
                src={courseIcon}
                fallback={defaultCourseIcon}
                style={{ width: 16, height: 16, marginLeft: 4 }}
              />
            </span>
          </Typography>
        </Grid>
        {typeof plannedDate === 'string' && plannedDate.length > 0 && (
          <Grid item className={css.item}>
            <Typography variant="subtitle2" component="span">
              Желаемая дата начала обучения:&nbsp;
            </Typography>
            <Typography variant="body1" gutterBottom>
              {plannedDate}
            </Typography>
          </Grid>
        )}
        {teacherUser !== null && (
          <Grid item className={css.item}>
            <Typography variant="subtitle2" component="span">
              Назначен преподаватель:&nbsp;
            </Typography>
            <Typography variant="body1" gutterBottom>
              {teacherUser.last_name_and_initials}
            </Typography>
          </Grid>
        )}
        {typeof comment === 'string' && comment.length > 0 && (
          <Grid item className={css.item}>
            <Typography variant="subtitle2" component="span">
              Комментарий:&nbsp;
            </Typography>
            <Typography variant="body1" gutterBottom>
              {comment}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

ExpansionDetails.propTypes = {
  comment: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  subjectName: PropTypes.string.isRequired,
  plannedDate: PropTypes.string,
  courseIcon: PropTypes.string,
  teacherUser: PropTypes.shape({
    last_name_and_initials: PropTypes.string,
  }),
};

ExpansionDetails.defaultProps = {
  plannedDate: null,
  courseIcon: false,
  teacherUser: null,
};

export default ExpansionDetails;
