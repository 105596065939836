import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { editSchedulerState } from '../../store/modules/selection';
import { parseProbableNull } from '../../utils/validators';

import View from './Scheduler/View';

const useStyles = makeStyles((theme) => ({
  bar: {
    position: 'relative',
    maxHeight: 64,
  },
  innerBar: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  title: {
    flex: 1,
  },
  viewCont: {
    height: 'calc(100% - 64px)',
    minHeight: 750,
  },
  noIntervals: {
    padding: theme.spacing(3),
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit />
));

const Scheduler = ({
  show, intervals, currentTeacher, editScheduler,
}) => {
  const css = useStyles();
  const { last_name_and_initials: teacherInitials } = parseProbableNull(currentTeacher);

  const handleClose = () => editScheduler({ showScheduler: false });

  return (
    <Dialog
      fullScreen
      open={show}
      onClose={handleClose}
      TransitionComponent={Transition}
      transitionDuration={300}
    >
      <AppBar className={css.bar} color="secondary">
        <Toolbar className={css.innerBar}>
          <Typography variant="h6" className={css.title}>
            <span>{teacherInitials}</span>
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={css.viewCont}>
        {Array.isArray(intervals) && intervals.length > 0 ? (
          <View intervals={intervals} />
        ) : (
          <Typography variant="h4" className={css.noIntervals}>
            Интервалы отсутствуют
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

Scheduler.propTypes = {
  show: PropTypes.bool.isRequired,
  intervals: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.oneOf([null]).isRequired,
  ]),
  currentTeacher: PropTypes.oneOfType([PropTypes.shape(), PropTypes.oneOf([null]).isRequired]),
  editScheduler: PropTypes.func.isRequired,
};

Scheduler.defaultProps = {
  intervals: null,
  currentTeacher: null,
};

const mapStateToProps = ({
  selection: {
    scheduler: {
      showScheduler, teacherId, intervals, teacherInstance,
    },
  },
}) => ({
  intervals,
  show: showScheduler,
  currentTeacherId: teacherId,
  currentTeacher: teacherInstance,
});

const mapDispatchToProps = {
  editScheduler: editSchedulerState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
