import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import {
  Typography, Paper, Container, Box, Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import StatusChip from '../components/ClaimList/StatusChip';
import ClaimData from '../components/ClaimItem/ClaimData';

const useStyles = makeStyles((theme) => ({
  paperCont: {
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

export default (WrappedComponent) => {
  const ClaimLayout = ({
    claimsList = [],
    currentClaim,
    getClaimsList,
    setClaim,
    fetchedClaims,
  }) => {
    const css = useStyles();
    const [initialEffect, setInitialEffect] = useState(true);
    const [badClaimId, setClaimIdStatus] = useState(false);
    const { id } = useParams();
    const { pathname } = useLocation();
    const [redirPath] = pathname.split('/').filter((str) => str.length > 0);
    const claimId = parseInt(id, 10);

    // Initial get claims from API
    useEffect(() => {
      async function fetchClaims() {
        const claims = await getClaimsList();
        return claims;
      }
      if (!fetchedClaims) {
        fetchClaims();
      }
    }, [fetchedClaims, getClaimsList]);

    // check and set current claim
    useEffect(() => {
      if (fetchedClaims) {
        const hasClaims = claimsList.length > 0;
        if (hasClaims) {
          if (currentClaim) {
            if (currentClaim.id !== claimId) {
              setClaim(claimId);
            }
          } else {
            if (initialEffect) {
              setClaim(claimId);
              // hmmm....
              setTimeout(() => {
                setInitialEffect(false);
              }, 500);
              return;
            }
            setClaimIdStatus(true);
          }
        } else {
          setClaimIdStatus(true);
        }
      }
    }, [fetchedClaims, claimsList, initialEffect, claimId, currentClaim, setClaim]);

    if (badClaimId) return <Redirect to={{ pathname: `/${redirPath}` }} />;

    const {
      status,
      user,
      course,
      students_group: studentsGroup,
      planned_start_date: plannedDate,
      manager_user: managerUser,
    } = currentClaim;

    return (
      <Container maxWidth="lg">
        {currentClaim && (
          <Paper elevation={1} className={css.paperCont}>
            <Typography variant="h2">
              Заявка ID:&nbsp;
              {claimId}
              <span style={{ marginLeft: 24 }}>
                <StatusChip status={status} />
              </span>
            </Typography>
            <Divider className={css.divider} />
            <Box>
              <ClaimData
                user={user}
                course={course}
                studentsGroup={studentsGroup}
                plannedDate={plannedDate}
                managerUser={managerUser}
              />
              <Box>
                <WrappedComponent />
              </Box>
            </Box>
          </Paper>
        )}
      </Container>
    );
  };

  ClaimLayout.propTypes = {
    fetchedClaims: PropTypes.bool.isRequired,
    claimsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    currentClaim: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]).isRequired,
    getClaimsList: PropTypes.func.isRequired,
    setClaim: PropTypes.func.isRequired,
  };

  return ClaimLayout;
};
