import { setFetching, showSnack, snackTypes } from '../common';
import { provider, apiEndpoints } from '../../../utils/api';

const initialState = {
  managersList: [],
  fetchedManagersList: false,
};

const actionTypes = {
  GET_MANAGERS: 'GET_MANAGERS',
};

export default (state = initialState, action = { type: null, payload: null }) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_MANAGERS: {
      return {
        ...state,
        managersList: payload,
        fetchedManagersList: true,
      };
    }
    default: {
      return state;
    }
  }
};

// Actions
export const getManagers = () => async (dispatch) => {
  try {
    dispatch(setFetching(true));
    const res = await provider.get(apiEndpoints.GET_MANAGERS);
    if (res) {
      dispatch({ type: actionTypes.GET_MANAGERS, payload: res });
      dispatch(setFetching(false));
      return res;
    }
  } catch (error) {
    console.log('error: ', error);
    const { msg } = error.data || {};
    dispatch(setFetching(false));
    dispatch(showSnack({ type: snackTypes.error, text: msg || 'Что-то пошло не так' }));
    throw error;
  }
  dispatch(setFetching(false));
  return false;
};
